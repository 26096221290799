import { Modal } from '@ir/client/components/Modal';
import { CustomMobxForm, plugins } from '@ir/client/components/form';
import { appStore, useAppStore } from '@ir/client/store';
import { Grid } from '@material-ui/core';
import type Form from 'mobx-react-form';
import { observer } from 'mobx-react-lite';

import './styles.css';
import { userFields } from '@ir/client/views/admin/pages/User';
import { UserInfo } from '@ir/client/views/admin/pages/User/User';
import { createNotification } from '@ir/client/views/notifications';

const hooks = {
  onSuccess(form: Form) {
    // get field values
    const values = {
      ...form.values(),
      sites: form.values()?.sites?.map?.((siteId) => Number(siteId)) ?? [],
      dashboardNotifications: form.values().dashboardNotifications,
      textNotifications: form.values().textNotifications,
      emailNotifications: form.values().emailNotifications,
      role: form.values().role,
    };
    const createUser = async () => {
      try {
        await appStore.graphql.mutateCreateUser({
          user: values,
        });
        createNotification({
          id: 'createUser',
          type: 'success',
          title: 'User created successfully',
        });
        appStore.modal.hideModal();
        form.reset();
      } catch (_e) {
        createNotification({
          id: 'createUser',
          type: 'danger',
          title: 'Error creating user',
        });
      }
    };

    createUser();
  },
  onError(form: Form) {
    console.error('Error submitting form', form.errors());
  },
};
const myForm = new CustomMobxForm(
  { fields: { ...userFields } },
  { plugins, hooks, options: { showErrorsOnReset: false } }
);

export const AddUser = observer(() => {
  const store = useAppStore();
  return (
    <Modal onClose={store.modal.hideModal}>
      <Grid container={true} xs={12} className="user-edit-table" spacing={3}>
        <UserInfo form={myForm} />
      </Grid>
    </Modal>
  );
});
