import { css } from '@emotion/react';
import { Modal } from '@ir/client/components/Modal';
import { useAppStore } from '@ir/client/store';
import { useDashboardTranslation } from '@ir/client/translations/use-dashboard-translation';
import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { observer, useLocalObservable } from 'mobx-react-lite';
import QRCode from 'qrcode.react';
import type { FC, PropsWithChildren } from 'react';

interface MfaSetupProps extends PropsWithChildren<{}> {
  open: boolean;
  qrCode: string;
  code: string;
  closeModal: () => void;
  submit: () => void;
}

export const MfaSetup: FC<MfaSetupProps> = observer((props: MfaSetupProps) => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();
  const state = useLocalObservable(() => ({
    code: '',
    deviceName: '',
    error: '',
  }));
  return (
    <Modal isOpen={props.open} onClose={props.closeModal}>
      <div
        css={css`
          padding: 2rem;
        `}
      >
        <Typography variant="h6" align="center" color="primary">
          Please scan this QR Code with any authentication app
          <br />
          e.g. Google Authenticator, Authy, Microsoft Authenticator etc.
        </Typography>
        <form
          noValidate={true}
          css={css`
            margin-top: 1rem;
          `}
        >
          <Grid
            container={true}
            spacing={1}
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <Grid container={true} item={true} xs={12} justifyContent="center">
              <QRCode value={props.qrCode} />
            </Grid>
            <Grid container={true} item={true} xs={12} justifyContent="center" alignItems="center">
              Your backup code is:&ensp;<Typography variant="subtitle2">{props.code}</Typography>
              <br />
            </Grid>
          </Grid>
          <Divider
            css={css`
              margin-bottom: 1rem;
            `}
          />
          <Typography variant="subtitle2">
            {t(
              'after-scanning-the-qr-code-enter-your-verification-code-displayed-in-your-authenticator-app-and-optionally-set-a-name-for-your-device'
            )}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required={true}
            fullWidth={true}
            name="Verification Code"
            type="Verification Code"
            id="Verification Code"
            label="Verification Code"
            onChange={(e) => {
              state.code = e.target.value;
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth={true}
            name="Device Name"
            type="Device Name"
            id="Device Name"
            label="Device Name"
            onChange={(e) => {
              state.deviceName = e.target.value;
            }}
          />
          {state.error ? (
            <Typography component="h1" variant="h5" align="center" color="primary">
              {t(state.error)}
            </Typography>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            disabled={!state.code}
            onClick={async () => {
              try {
                await store.graphql.mutateVerifyTOTPCode({ code: state.code, deviceName: state.deviceName });
                props.submit();
                props.closeModal();
              } catch (_e) {
                state.error =
                  'Error Confirming Code, Please Try Again. If the problem persists, try again later or contact an administrator';
              }
            }}
          >
            {t('submit-code')}
          </Button>
        </form>
      </div>
    </Modal>
  );
});
