import { MaskComponent } from '@ir/client/components/InputMask';
import { Modal } from '@ir/client/components/Modal';
import type { CustomMobxForm } from '@ir/client/components/form';
import type { SiteModel } from '@ir/client/store';
import { NotificationLevels, RoleLevels, SavingState, useAppStore, userQuery } from '@ir/client/store';
import { useDashboardTranslation } from '@ir/client/translations/use-dashboard-translation';
import { SiteList } from '@ir/client/views/admin/components/SiteList';
import { checkUserExists, testNotifications } from '@ir/client/views/admin/duck';
import { createNotification } from '@ir/client/views/notifications';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fade,
  FormControl,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { CheckCircle, ChevronLeft, ChevronRight, DeleteForever, NotificationsNone, Security } from '@material-ui/icons';
import debounce from 'lodash/debounce';
import MUP from 'material-ui-phone-number';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { PropsWithChildren, ReactNode } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { MfaSetup } from './MFASetup';

const MuiPhoneNumber = MUP.default ?? MUP;
interface UserInfoProps {
  userId?: string;
  index?: string;
  sites?: SiteModel[];
  classes?: any;
  form: CustomMobxForm;
  addFields?: ReactNode;
  isEditing?: boolean;
  deselect?: () => void;
}

function ShowState(props: PropsWithChildren<{ show: boolean }>) {
  return (
    <div {...props} style={{ display: props.show ? 'block' : 'none', marginLeft: '0.5rem' }}>
      {props.children}
    </div>
  );
}

export const UserInfo = observer((props: UserInfoProps) => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();
  const [isBasic, setIsBasic] = useState(false);
  const [_isTesting, setIsTesting] = useState(false);
  const optionsButtonRef = useRef();
  const state = useLocalObservable(() => ({
    qrCode: '',
    code: '',
    totpModalOpen: false,
    verifyDelete: false,
    optionsMenuOpen: false,
    setOptionsMenuOpen: (value: boolean) => {
      state.optionsMenuOpen = value;
    },
    setVerifyDelete: (value: boolean) => {
      state.verifyDelete = value;
    },
    userError: false,
    closeModal: () => {
      state.totpModalOpen = false;
    },
    submitSuccessful: () => {
      props.form.$('twofaEnabled').set(true);
    },
    handleClose: () => {
      state.setOptionsMenuOpen(false);
    },
    checkUserName: debounce(async () => {
      checkUserExists(props.form.$('username').value)
        .then((response) => {
          state.userError = response.status !== 200;
        })
        .catch(() => {
          state.userError = true;
        });
    }, 500),
  }));
  const country = useMemo<string>(
    () => navigator?.language?.split('-')?.[1]?.toLowerCase() || 'us',
    [navigator.language]
  );
  const sites = useMemo(() => props.form.$('sites').value, [props.form.$('username').value]);
  useEffect(() => {
    if (store.modal.modalType) {
      if (props.form.$('role').value === RoleLevels.Basic) {
        setIsBasic(true);
        props.form.set({ email: 'basic@user.com', password: 'Test12345!', passwordConfirm: 'Test12345!' });
      } else if (isBasic) {
        props.form.set({ email: '', password: '', passwordConfirm: '' });
        setIsBasic(true);
      } else {
        setIsBasic(false);
      }
    }
  }, [props.form.$('role').value]);
  return (
    <form
      autoComplete="off"
      style={{ padding: '5%' }}
      onSubmit={(e) => props.form.onSubmit(e as any)}
      className="multi-form"
    >
      <Grid container={true} alignItems="stretch" spacing={3}>
        <Grid item={true} xs={6}>
          <TextField
            {...props.form.$('username').bind({})}
            error={
              state.userError
                ? 'Username taken'
                : props.form.$('username').error
                  ? props.form.$('username').error
                  : false
            }
            onChange={(e) => {
              props.form.$('username').set(e.target.value);
              state.checkUserName();
            }}
            disabled={props.isEditing}
            label={t(props.form.$('username').label)}
          />
        </Grid>
        <Grid item={true} xs={6}>
          <FormControl className="form-control-select">
            <InputLabel shrink={true} htmlFor="role-simple">
              {`${t('role')} ${t('(required)')}`}
            </InputLabel>
            <Select {...props.form.$('role').bind({})}>
              <MenuItem value={RoleLevels.Basic} children={`${t('basic')} ${t('user')}`} />
              <MenuItem value={RoleLevels.User} children={`${t('user')}`} />
              <MenuItem value={RoleLevels.Admin} children={`${t('admin')}`} />
              <MenuItem
                value={RoleLevels.Owner}
                children={`${t('owner')}`}
                disabled={store.auth.role !== RoleLevels.Owner}
              />
            </Select>
          </FormControl>
        </Grid>
        {props.addFields}
        <Grid item={true} xs={4}>
          <TextField {...props.form.$('firstName').bind({})} label={t(props.form.$('firstName').label)} />
        </Grid>
        <Grid item={true} xs={4}>
          <TextField {...props.form.$('lastName').bind({})} label={t(props.form.$('lastName').label)} />
        </Grid>
        <Grid item={true} xs={4}>
          <TextField {...props.form.$('middleName').bind({})} label={t(props.form.$('middleName').label)} />
        </Grid>
        {props.form.$('role').value !== RoleLevels.Basic && (
          <>
            <Grid item={true} xs={6}>
              <TextField {...props.form.$('email').bind({})} label={t(props.form.$('email').label)} />
            </Grid>
            <Grid item={true} xs={6}>
              <MuiPhoneNumber
                {...props.form.$('phone').bind({})}
                defaultCountry={country}
                disableAreaCodes={true}
                label={t(props.form.$('phone').label)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                {...props.form.$('vaultPin').bind({})}
                label={t(props.form.$('vaultPin').label)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: MaskComponent,
                  inputProps: { component: MaskedInput, type: 'pin' },
                }}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <FormControl className="form-control-select">
                <InputLabel shrink={true} htmlFor="notifications-simple">
                  {`${t('notifications')}`}
                </InputLabel>
                <Select {...props.form.$('notifications').bind({})} defaultValue={false}>
                  <MenuItem value={false as any} children={`${t('off')}`} />
                  <MenuItem value={true as any} children={`${t('on')}`} />
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={4}>
              <FormControl className="form-control-select">
                <InputLabel shrink={true} htmlFor="notifications-simple">
                  {`${t('dashboard')} ${t('notifications')}`}
                </InputLabel>
                <Select {...props.form.$('dashboardNotifications').bind({})} defaultValue={NotificationLevels.Off}>
                  <MenuItem value={NotificationLevels.Off} children={`${t('off')}`} />
                  <MenuItem value={NotificationLevels.Alarm} children={`${t('alarm')}`} />
                  <MenuItem value={NotificationLevels.Warning} children={`${t('warning')}`} />
                  <MenuItem value={NotificationLevels.Info} children={`${t('info')}`} />
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={4}>
              <FormControl className="form-control-select">
                <InputLabel shrink={true} htmlFor="notifications-simple">
                  {`${t('email')} ${t('notifications')}`}
                </InputLabel>
                <Select {...props.form.$('emailNotifications').bind({})} defaultValue={NotificationLevels.Off}>
                  <MenuItem value={NotificationLevels.Off} children={`${t('off')}`} />
                  <MenuItem value={NotificationLevels.Alarm} children={`${t('alarm')}`} />
                  <MenuItem value={NotificationLevels.Warning} children={`${t('warning')}`} />
                  <MenuItem value={NotificationLevels.Info} children={`${t('info')}`} />
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={4}>
              <FormControl className="form-control-select">
                <InputLabel shrink={true} htmlFor="notifications-simple">
                  {`${t('text')} ${t('notifications')}`}
                </InputLabel>
                <Select {...props.form.$('textNotifications').bind({})} defaultValue={NotificationLevels.Off}>
                  <MenuItem value={NotificationLevels.Off}>{`${t('off')}`}</MenuItem>
                  <MenuItem value={NotificationLevels.Alarm} children={`${t('alarm')}`} />
                  <MenuItem value={NotificationLevels.Warning} children={`${t('warning')}`} />
                  <MenuItem value={NotificationLevels.Info} children={`${t('info')}`} />
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={12} style={{ height: '50px' }}>
              <Divider style={{ height: '2px', marginBottom: '4px' }} />
              <Typography variant="h6">{`${t('permissions')}`}</Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <SiteList
                checked={props.form.$('sites').value}
                setChecked={(checked) => {
                  props.form.$('sites').set(checked);
                }}
              />
            </Grid>
          </>
        )}
        <Grid item={true} xs={2}>
          <Button
            color="primary"
            disabled={
              !!state.userError ||
              (!props.form.changed && sites === props.form.$('sites').value) ||
              (props.form.$('role').value === RoleLevels.Basic
                ? !(
                    !!props.form.$('username').value &&
                    !!props.form.$('firstName').value &&
                    !!props.form.$('lastName').value
                  )
                : !props.form.isValid)
            }
            onClick={() => {
              if (!props.form.submitting) {
                props.form.submit(props.form.values());
                store.graphql.queryUsers({}, userQuery);
              }
            }}
            style={{
              backgroundColor: store.admin.savingUser === SavingState.DONE && '#00c853',
              display: 'flex',
              justifyContent: 'center',
              transition: 'background-color ease 0.25s',
            }}
            variant="contained"
          >
            {`${t('save')}`}
            <Fade in={store.admin.savingUser === SavingState.RUNNING}>
              <ShowState show={store.admin.savingUser === SavingState.RUNNING}>
                <CircularProgress color="inherit" size={24} />
              </ShowState>
            </Fade>
            <Fade in={store.admin.savingUser === SavingState.DONE}>
              <ShowState show={store.admin.savingUser === SavingState.DONE}>
                <CheckCircle />
              </ShowState>
            </Fade>
          </Button>
        </Grid>
        {props.isEditing ? (
          <Grid item={true} xs={10} justifyContent="flex-end" style={{ display: 'flex' }}>
            <Button
              aria-controls="simple-menu"
              ref={optionsButtonRef}
              aria-haspopup="true"
              style={{ width: '200px' }}
              onClick={() => state.setOptionsMenuOpen(!state.optionsMenuOpen)}
            >
              {state.optionsMenuOpen ? (
                <ChevronRight style={{ rotate: '90deg' }} />
              ) : (
                <ChevronLeft style={{ rotate: '90deg' }} />
              )}{' '}
              Options
            </Button>
            <Popper placement="top" open={state.optionsMenuOpen} anchorEl={optionsButtonRef.current} transition={true}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={state.handleClose}>
                      <MenuList style={{ width: '200px' }} id="simple-menu">
                        {store.auth.role === RoleLevels.Admin ||
                          store.auth.role === RoleLevels.Owner ||
                          (store.auth.role === RoleLevels.Debug && (
                            <MenuItem
                              color="secondary"
                              style={{ color: '#fa1717' }}
                              onClick={() => {
                                state.setVerifyDelete(true);
                                state.setOptionsMenuOpen(false);
                              }}
                            >
                              {t('delete')} {t('user')} <DeleteForever style={{ marginLeft: 'auto' }} />
                            </MenuItem>
                          ))}
                        <MenuItem
                          disabled={props.isEditing && props.form.$('username').value !== store.auth.username}
                          onClick={async () => {
                            const val = !props.form.$('twofaEnabled').value;
                            const ret = await store.graphql.mutateToggleMFA({ enabled: val });
                            if (val) {
                              state.code = ret?.toggleMFA;
                              state.qrCode = `otpauth://totp/AWSCognito:${store.auth.username}?secret=${ret?.toggleMFA}&issuer=industrialradiography`;
                              state.totpModalOpen = true;
                            } else {
                              props.form.$('twofaEnabled').set(false);
                            }
                            state.setOptionsMenuOpen(false);
                          }}
                        >
                          {props.form.$('twofaEnabled').value ? 'Disable' : 'Enable'} MFA{' '}
                          <Security style={{ marginLeft: 'auto' }} />
                        </MenuItem>
                        <MenuItem
                          onClick={async () => {
                            setIsTesting(true);
                            testNotifications(props.form.$('email').value, props.form.$('phone').value);
                            setIsTesting(false);
                            state.setOptionsMenuOpen(false);
                          }}
                        >
                          {t('test')} {t('notifications')} <NotificationsNone style={{ marginLeft: 'auto' }} />
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        ) : null}
      </Grid>
      <Modal isOpen={state.verifyDelete} onClose={() => state.setVerifyDelete(false)}>
        <div style={{ padding: '1rem 0' }}>
          <Typography variant="h5">You are permanently deleting user: {props.form.$('username').value}</Typography>
          <Typography variant="h6">This action cannot be undone!</Typography>
        </div>

        <div style={{ gap: '12px', display: 'grid', gridTemplateColumns: '1fr 2fr', padding: '1rem 0' }}>
          <Button variant="contained" onClick={() => state.setVerifyDelete(false)}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              state.setVerifyDelete(false);
              try {
                await store.graphql.mutateDeleteUser({ username: props.form.$('username').value });
                store.graphql.users.delete(props.userId);
                props.deselect?.();
                createNotification({
                  type: 'success',
                  title: `Successfully deleted user: ${props.form.$('username').value}`,
                });
              } catch (_e) {
                createNotification({
                  type: 'danger',
                  title: `Error deleting user: ${props.form.$('username').value}`,
                });
              }
            }}
          >
            {t('delete')}
          </Button>
        </div>
      </Modal>
      <MfaSetup
        open={state.totpModalOpen}
        code={state.code}
        qrCode={state.qrCode}
        closeModal={state.closeModal}
        submit={state.submitSuccessful}
      />
    </form>
  );
});
