import { makeAutoObservable } from 'mobx';

export enum SavingState {
  NONE,
  RUNNING,
  DONE,
}

export class Admin {
  savingUser: SavingState = SavingState.NONE;

  constructor() {
    makeAutoObservable(this);
  }
}
