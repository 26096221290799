/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { prop, tProp, Model } from 'mobx-keystone';
import { QueryBuilder } from 'mk-gql';

/**
 * EventBase
 * auto generated base class for the model EventModel.
 */
export class EventModelBase extends Model({
  __typename: tProp('Event'),
  deviceId: prop<number>().withSetter(),
  id: prop<number>().withSetter(),
  latitude: prop<number | null>().withSetter(),
  longitude: prop<number | null>().withSetter(),
  telitTimestamp: prop<any | null>().withSetter(),
  timestamp: prop<any | null>().withSetter(),
  type: prop<string | null>().withSetter(),
}) {
  getRefId() {
    return String(this.id);
  }
}

export class EventModelSelector extends QueryBuilder {
  get deviceId() {
    return this.__attr(`deviceId`);
  }
  get id() {
    return this.__attr(`id`);
  }
  get latitude() {
    return this.__attr(`latitude`);
  }
  get longitude() {
    return this.__attr(`longitude`);
  }
  get telitTimestamp() {
    return this.__attr(`telitTimestamp`);
  }
  get timestamp() {
    return this.__attr(`timestamp`);
  }
  get type() {
    return this.__attr(`type`);
  }
}
export function selectFromEvent() {
  return new EventModelSelector();
}

export const eventModelPrimitives = selectFromEvent().deviceId.latitude.longitude.telitTimestamp.timestamp.type;
