import { ExtendedModel, model } from 'mobx-keystone';
import { CamerasPresentModelBase } from './CamerasPresentModel.base';

/* A graphql query fragment builders for CamerasPresentModel */
export {
  selectFromCamerasPresent,
  camerasPresentModelPrimitives,
  CamerasPresentModelSelector,
} from './CamerasPresentModel.base';

/**
 * CamerasPresentModel
 */
@model('CamerasPresent')
export class CamerasPresentModel extends ExtendedModel(CamerasPresentModelBase, {}) {}
