import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import type english from './en/dashbord.json';

export const useDashboardTranslation = () => {
  const { t, i18n } = useTranslation('dashboard');
  const r = useCallback((value: keyof typeof english | string = ''): string => {
    const val = value?.toLowerCase()?.split(' ');
    const ret = t(val?.join('-'));
    return ret !== val?.join('-') ? ret : val?.map((v) => t(v))?.join(' ') || value;
  }, []);
  return { t: r, i18n };
};
