/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { prop, tProp, Model, type Ref } from 'mobx-keystone';
import { QueryBuilder } from 'mk-gql';
import type { DeviceModel } from './DeviceModel';

import { DeviceModelSelector } from './DeviceModel';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  device: DeviceModel;
};

/**
 * StatsBase
 * auto generated base class for the model StatsModel.
 */
export class StatsModelBase extends Model({
  __typename: tProp('Stats'),
  device: prop<Ref<DeviceModel>>().withSetter(),
  end: prop<any | null>().withSetter(),
  expectedNumHeartbeats: prop<number | null>().withSetter(),
  heartbeatsPercentage: prop<number | null>().withSetter(),
  id: prop<number>().withSetter(),
  longestInUse: prop<string | null>().withSetter(),
  longestInactive: prop<string | null>().withSetter(),
  numDocked: prop<number | null>().withSetter(),
  numHasLocation: prop<number | null>().withSetter(),
  numHasLowBattery: prop<number | null>().withSetter(),
  numHeartbeats: prop<number | null>().withSetter(),
  numInUse: prop<number | null>().withSetter(),
  numMessages: prop<number | null>().withSetter(),
  start: prop<any | null>().withSetter(),
}) {
  getRefId() {
    return String(this.id);
  }
}

export class StatsModelSelector extends QueryBuilder {
  get end() {
    return this.__attr(`end`);
  }
  get expectedNumHeartbeats() {
    return this.__attr(`expectedNumHeartbeats`);
  }
  get heartbeatsPercentage() {
    return this.__attr(`heartbeatsPercentage`);
  }
  get id() {
    return this.__attr(`id`);
  }
  get longestInUse() {
    return this.__attr(`longestInUse`);
  }
  get longestInactive() {
    return this.__attr(`longestInactive`);
  }
  get numDocked() {
    return this.__attr(`numDocked`);
  }
  get numHasLocation() {
    return this.__attr(`numHasLocation`);
  }
  get numHasLowBattery() {
    return this.__attr(`numHasLowBattery`);
  }
  get numHeartbeats() {
    return this.__attr(`numHeartbeats`);
  }
  get numInUse() {
    return this.__attr(`numInUse`);
  }
  get numMessages() {
    return this.__attr(`numMessages`);
  }
  get start() {
    return this.__attr(`start`);
  }
  device(builder?: string | DeviceModelSelector | ((selector: DeviceModelSelector) => DeviceModelSelector)) {
    return this.__child(`device`, DeviceModelSelector, builder);
  }
}
export function selectFromStats() {
  return new StatsModelSelector();
}

export const statsModelPrimitives =
  selectFromStats().end.expectedNumHeartbeats.heartbeatsPercentage.longestInUse.longestInactive.numDocked.numHasLocation
    .numHasLowBattery.numHeartbeats.numInUse.numMessages.start;
