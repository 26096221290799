import { type StatusColorsConfig, darkTheme } from '@ir/client/types/theme';

export function stateToStatus(status: string) {
  switch (status.toLowerCase().replace(/\s/g, '')) {
    case 'radsourcemissing':
      return darkTheme.status.critical;
    case 'alarmed':
    case 'lost':
      return darkTheme.status.alarm;
    case 'in use':
    case 'inuse':
      return darkTheme.status.inUse;
    case 'docked':
    case 'invault':
      return darkTheme.status.docked;
    default:
      return undefined;
  }
}

export enum MarkerShapes {
  Circle = 'circle',
  Hex = 'hex',
}

interface ClusterArgs {
  shape: MarkerShapes;
  colors: StatusColorsConfig;
}

export const clusterStyles = {
  [MarkerShapes.Circle]: (colors: StatusColorsConfig) => ({
    shape: `    
    background: ${colors.gradient};
    border: 1px solid ${colors.background};
    box-shadow: 0 0 10px ${colors.background};
    `,
  }),
  [MarkerShapes.Hex]: (colors: StatusColorsConfig) => ({
    innerPolygon: `
      fill: ${colors.gradient};
    `,
    outerPolygon: `
      fill: ${colors.gradient};
      stroke: ${colors.background};
    `,
  }),
};

export function getClusterStyle<Args extends ClusterArgs>(args: Args) {
  switch (args.shape) {
    case MarkerShapes.Circle:
      return {
        root: `
      background-color: ${args.colors.gradient};
      border: 1px solid ${args.colors.background};
      box-shadow: 0 0 10px ${args.colors.dark};
    `,
      };
    case MarkerShapes.Hex:
      return {
        innerPolygon: `
          fill: ${args.colors.gradient};
        `,
        outerPolygon: `
          fill: ${args.colors.gradient};
          stroke: ${args.colors.background};
        `,
      };
    default:
      return undefined;
  }
}
