import styled from '@emotion/styled';
import { Badge, Button, Chip } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';

import { Views } from '@ir/client/Router';
import { AlertCriticality, NotificationLevels, alertCountsModelPrimitives, useAppStore } from '@ir/client/store';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

const ChipStyle = styled(Chip)`
  & span {
    padding: 2px 5px;
  }
  height: 15px;
`;

const ButtonBadge = Button as any;
export const AlertBell = observer(() => {
  const store = useAppStore();
  const history = useHistory();
  const location = useLocation();
  const { data } = useQuery(
    'alertCounts',
    async () =>
      await store.graphql.queryAlertCount(
        {},
        alertCountsModelPrimitives
          .Alarm((a) => a.complete.incomplete)
          .Warning((a) => a.complete.incomplete)
          .Info((a) => a.complete.incomplete)
          .toString()
      )
  );
  if (store.auth.dashboardNotifications === NotificationLevels.Off) return null;
  return (
    <div style={{ height: '100%' }}>
      <ButtonBadge
        className="alert-button"
        component={Badge as any}
        color="secondary"
        style={{ height: '100%', padding: '0 1rem' }}
        // disabled={store.auth.dashboardNotifications === NotificationLevels.Off}
        onClick={() => history.push(`/${Views.Notifications}`)}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Notifications
            style={{
              marginLeft: '10px',
              fontSize: '25px',
              color: location.pathname.includes(`${Views.Notifications}`) ? 'white' : null,
            }}
          />
          <div style={{ display: 'flex' }}>
            <ChipStyle
              style={{
                // left: "1rem",
                color: '#717171',
                fontSize: '10px',
                background: '#ff6961',
              }}
              label={data?.alertCount?.[AlertCriticality.Alarm]?.incomplete || 0}
              size="small"
            />
            <ChipStyle
              style={{
                marginLeft: '-2px',
                fontSize: '10px',
                background: '#7bb38d',
                color: '#717171',
              }}
              label={data?.alertCount?.[AlertCriticality.Info]?.incomplete || 0}
              size="small"
            />
            <ChipStyle
              style={{
                marginLeft: '-2px',
                fontSize: '10px',
                background: '#FDFD97',
                color: '#717171',
              }}
              label={data?.alertCount?.[AlertCriticality.Warning]?.incomplete || 0}
              size="small"
            />
          </div>
        </div>
      </ButtonBadge>
    </div>
  );
});
