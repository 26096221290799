import type { ReactElement } from 'react';
import { MaterialSymbol, type MaterialSymbolProps, type PolymorphicMaterialSymbolProps } from 'react-material-symbols';

export type MaterialIconProps<Ico extends MaterialSymbolProps['icon']> = PolymorphicMaterialSymbolProps<'span'> & {
  icon: Ico;
};

export type IcoFC<Ico extends MaterialSymbolProps['icon']> = (props: Partial<MaterialIconProps<Ico>>) => ReactElement;
export const IconFCFactory =
  <Ico extends MaterialSymbolProps['icon']>(
    initProps: Pick<MaterialIconProps<Ico>, 'icon'> & Partial<Omit<MaterialIconProps<Ico>, 'icon'>>
  ) =>
  (props: Partial<MaterialIconProps<Ico>>) => {
    return <MaterialIcon {...initProps} {...props} />;
  };

export function MaterialIcon<Ico extends MaterialSymbolProps['icon']>({ icon, ...props }: MaterialIconProps<Ico>) {
  return (
    <MaterialSymbol
      as="span"
      size={48}
      weight={300}
      {...props}
      icon={icon}
      style={{ display: 'inline-block', ...props.style }}
    />
  );
}
