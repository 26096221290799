import {
  CustomMobxForm,
  hasNoWhiteSpace,
  isAlpha,
  isAlphanumeric,
  isBoolean,
  isLength,
  isNumeric,
  isOptionalList,
  isRequired,
  plugins,
} from '@ir/client/components/form';
import type { UserModel } from '@ir/client/store';
import {
  NotificationLevels,
  RoleLevels,
  SavingState,
  appStore,
  siteModelPrimitives,
  useAppStore,
  userQuery,
} from '@ir/client/store';
import { useDashboardTranslation } from '@ir/client/translations/use-dashboard-translation';
import { createThemeWithOverrides } from '@ir/client/types/theme';
import { ModalTypes } from '@ir/client/views/modal/RootModal';
import {
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListSubheader,
  MuiThemeProvider,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Contacts } from '@material-ui/icons';
import { toJS } from 'mobx';
import { getSnapshot } from 'mobx-keystone';
// import type {FieldsDefinitions} from 'mobx-react-form';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { UserInfo } from './User';
import type Form from 'mobx-react-form';
import type { FieldPropsType } from 'mobx-react-form/src/models/FieldProps';

const theme = createThemeWithOverrides({
  palette: {
    type: 'light',
  },
});

export const userFields = {
  username: {
    name: 'username',
    label: 'Username (required)',
    validators: [isAlphanumeric, hasNoWhiteSpace, isLength({ min: 1, max: 60 })],
    bindings: 'MaterialTextField',
  },
  firstName: {
    name: 'firstName',
    label: 'First Name (required)',
    validators: [isAlpha, isLength({ min: 1, max: 60 })],
    bindings: 'MaterialTextField',
  },
  middleName: {
    name: 'middleName',
    label: 'Middle Name',
    validators: [isOptionalList({ validators: [isAlpha, isLength({ min: 0, max: 60 })] })],
    bindings: 'MaterialTextField',
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name (required)',
    validators: [isAlpha, isLength({ min: 1, max: 60 })],
    bindings: 'MaterialTextField',
  },
  email: {
    name: 'email',
    label: 'Email (required)',
    validators: [isLength({ min: 5, max: 60 })],
    bindings: 'MaterialTextField',
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    validators: [isOptionalList({ validators: [isAlphanumeric, isLength({ min: 0, max: 60 })] })],
    bindings: 'MaterialTextField',
  },
  vaultPin: {
    name: 'vaultPin',
    label: 'Vault Pin',
    validators: [isOptionalList({ validators: [isNumeric, isLength({ min: 3, max: 7 })] })],
    bindings: 'MaterialTextField',
  },
  role: {
    name: 'role',
    label: 'Role',
    validators: [isRequired, isLength({ min: 1, max: 15 })],
    bindings: 'MaterialSelectField',
  },
  notifications: {
    name: 'notifications',
    label: 'Notifications',
    validators: [isBoolean, isRequired],
    bindings: 'MaterialSelectField',
    value: false,
    default: false,
  },
  twofaEnabled: {
    name: 'twofaEnabled',
    label: 'Two-factor authentication',
    validators: [isBoolean, isRequired],
    bindings: 'MaterialSelectField',
    value: false,
    default: false,
  },
  dashboardNotifications: {
    name: 'dashboardNotifications',
    label: 'Dashboard Notifications',
    validators: [isAlpha, isRequired],
    bindings: 'MaterialSelectField',
    default: NotificationLevels.Off,
    value: NotificationLevels.Off,
  },
  textNotifications: {
    name: 'textNotifications',
    label: 'Text Notifications',
    validators: [isAlpha, isRequired],
    bindings: 'MaterialSelectField',
    default: NotificationLevels.Off,
    value: NotificationLevels.Off,
  },
  emailNotifications: {
    name: 'emailNotifications',
    label: 'Email Notifications',
    validators: [isAlpha, isRequired],
    bindings: 'MaterialSelectField',
    default: NotificationLevels.Off,
    value: NotificationLevels.Off,
  },
  sites: {
    name: 'sites',
    label: 'Sites',
    default: [],
  },
  id: {
    name: 'id',
    label: 'ID',
  },
} satisfies Record<string, FieldPropsType>;

export const roleLevelToNumber = (not: RoleLevels) => {
  if (not === RoleLevels.Basic) return 0;
  if (not === RoleLevels.User) return 1;
  if (not === RoleLevels.Admin) return 2;
  if (not === RoleLevels.Owner) return 3;
};
const hooks = {
  async onSuccess(form: Form) {
    // get field values
    appStore.admin.savingUser = SavingState.RUNNING;
    const formValues = form.values();
    await appStore.graphql.mutateEditUser({
      user: {
        id: formValues.id,
        firstName: formValues.firstName,
        middleName: formValues.middleName,
        lastName: formValues.lastName,
        username: formValues.username,
        email: formValues.email,
        vaultPin: formValues.vaultPin,
        sites: formValues.sites.map((siteId) => Number(siteId)),
        phone: form.values().phone.replace(/\D/g, ''),
        dashboardNotifications: form.values().dashboardNotifications,
        textNotifications: form.values().textNotifications,
        emailNotifications: form.values().emailNotifications,
        role: form.values().role,
      },
    });
    await appStore.auth.checkLogin();
    appStore.admin.savingUser = SavingState.DONE;
    setTimeout(() => {
      appStore.admin.savingUser = SavingState.NONE;
      const values = form.values();
      form.reset();
      form.update(values);
      form.validate();
    }, 1000);
  },
  onError(_form: Form) {
    // get all form errors
    appStore.admin.savingUser = SavingState.NONE;
  },
};
// const myForm = new CustomMobxForm({ fields: userFields }, { plugins, hooks });

// eslint-disable-next-line import/no-default-export
export default observer(() => {
  const store = useAppStore();
  useQuery('sites', async () => await store.graphql.querySites({}, siteModelPrimitives.toString()));
  const { t } = useDashboardTranslation();
  const state = useLocalObservable(() => ({
    selected: undefined as undefined | string,
    get userForm() {
      let formUser = undefined;
      try {
        formUser = getSnapshot(store.graphql.users.get(state.selected));
      } catch (_e) {
        //
      }
      if (!formUser) return new CustomMobxForm({ fields: userFields }, { plugins, hooks });

      return new CustomMobxForm(
        { fields: userFields, values: { ...formUser, sites: formUser.sites.map((site) => Number(site.id)) } },
        { plugins, hooks }
      );
    },
    resetSelected() {
      this.selected = undefined;
    },
    setSelectedUser(user) {
      // let formUser = user;
      // try {
      //   formUser = getSnapshot(user);
      // } catch (_e) {
      //   //
      // }
      // myForm.reset();
      // myForm.update(formUser);
      // myForm.validate();
      state.selected = user.id;
    },
    get selectedUser() {
      return toJS(store.graphql.users.get(state.selected));
    },
  }));

  useQuery(['users', store.modal.modalType], async () => await store.graphql.queryUsers({}, userQuery));

  return (
    <MuiThemeProvider theme={theme}>
      <div className="admin-page">
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Toolbar>
              <Grid item={true} xs={11} />
              <Grid item={true} xs={1}>
                <Button
                  variant="outlined"
                  style={{ background: '#f8f8f8' }}
                  onClick={() => store.modal.showModal(ModalTypes.AddUser)}
                >
                  {`${t('add')} ${t('user')}`}
                </Button>
              </Grid>
            </Toolbar>
          </Grid>
          <Grid item={true} xs={12}>
            <Paper style={{ margin: '0 2.5% 2.5% 2.5%' }}>
              <Grid container={true} className="user-edit-table">
                <Grid item={true} xs={2}>
                  <List className="user-sidebar">
                    <ListSubheader disableSticky={false} style={{ background: '#fff' }}>
                      <Typography style={{ color: 'gray', padding: '5px 0' }} variant="h5">
                        {`${t('users')}`}
                      </Typography>
                      <Divider variant="fullWidth" />
                    </ListSubheader>
                    {Array.from(store.graphql?.users.entries(), ([, row]: [string, UserModel]) => {
                      if (!row.username) return;
                      return (
                        <ListItem
                          button={true}
                          key={row.id}
                          disabled={
                            row.username !== store.auth.username &&
                            store.auth.role !== RoleLevels.Owner &&
                            row?.role &&
                            row?.role >= store.auth.role
                          }
                          onClick={() => state.setSelectedUser(row)}
                          className={`admin-sidebar-button ${state.selected === row.id ? 'selected' : ''}`}
                        >
                          {row.username}
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
                <Grid item={true} xs={10}>
                  {state.selected !== undefined ? (
                    <UserInfo
                      form={state.userForm}
                      userId={state.selected}
                      deselect={state.resetSelected}
                      isEditing={true}
                      index={state.selected!}
                    />
                  ) : (
                    <Container
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf: 'center',
                      }}
                    >
                      <Paper
                        elevation={2}
                        style={{
                          display: 'flex',
                          padding: '4rem',
                          alignItems: 'center',
                        }}
                      >
                        <Contacts style={{ marginRight: '1rem' }} />
                        <Typography variant="h4">{`${t('please-select-or-create-a-user')}`}</Typography>
                      </Paper>
                    </Container>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </MuiThemeProvider>
  );
});
