import type { Subtract } from '@ir/client/types';
import type { ReactElement } from 'react';
import type { MaterialSymbolProps } from 'react-material-symbols';
import { type IcoFC, IconFCFactory, type MaterialIconProps } from './MaterialIcon';
import { type SymbolMapping, SymbolNames } from './SymbolNames';

type DynamicSize = `s${number}${number | undefined}`;
type DynamicComponent = `c${number}${number | undefined}`;
type SizeRecord = Record<DynamicSize, ReactElement> &
  Record<DynamicComponent, (props: Partial<MaterialIconProps<any>>) => ReactElement>;
export type IconItem<Ico extends MaterialSymbolProps['icon']> = {
  name: Ico;
  component: IcoFC<Ico>;
  dynamic: ReactElement;
  small: ReactElement;
  medium: ReactElement;
  large: ReactElement;
} & SizeRecord;

export type AnyIconItem = IconItem<any>;

type SymbolComponentProxyShape<SymNames extends SymbolMapping, Depth extends number> = Depth extends 0
  ? never
  : {
      [Key in keyof SymNames]: SymNames[Key] extends infer Ico extends MaterialSymbolProps['icon']
        ? IconItem<Ico>
        : SymNames[Key] extends { [k: PropertyKey]: any }
          ? SymbolComponentProxyShape<SymNames[Key], Subtract<Depth, 1>>
          : never;
    };

function iconItemProxy<Ico extends MaterialSymbolProps['icon']>(icon: Ico) {
  const target = {
    name: icon,
    component: IconFCFactory({ icon }),
  };
  return new Proxy(target, {
    get(target: Pick<IconItem<Ico>, 'name' | 'component'>, p: string | symbol, receiver: any): any {
      if (typeof p === 'string' && (p.match(/^s\d+$/) || p.match(/^c\d+$/))) {
        return p.startsWith('s')
          ? target.component({ size: Number.parseInt(p.replace('s', '')) })
          : (props) => target.component({ size: Number.parseInt(p.replace('c', '')), ...props });
      } else if (p === 'small') {
        return target.component({ size: 12 });
      } else if (p === 'medium') {
        return target.component({ size: 28 });
      } else if (p === 'large') {
        return target.component({ size: 48 });
      } else {
        return Reflect.get(target, p, receiver);
      }
    },
  });
}
function createDeepProxy<T extends SymbolMapping>(target: T) {
  return new Proxy(target, {
    get(target, prop: string | symbol, receiver: any): any {
      const value = Reflect.get(target, prop, receiver);
      if (typeof value === 'string') {
        const v = value as MaterialSymbolProps['icon'];
        return iconItemProxy(v);
      } else if (typeof value === 'object') {
        return createDeepProxy(value); // Recurse into nested objects
      } else {
        return value;
      }
    },
  }) as any as SymbolComponentProxyShape<T, 5>;
}

export const IconMap = createDeepProxy(SymbolNames);
