import {
  alertModelPrimitives,
  deviceModelPrimitives,
  eventModelPrimitives,
  statsModelPrimitives,
  userModelPrimitives,
  vaultModelPrimitives,
} from './graphql';

export const alertQuery = alertModelPrimitives
  .handledBy((user) => user.firstName.lastName.username.id)
  .assignedTo((user) => user.firstName.lastName.username.id)
  .device((device) => device.id.serialNumber)
  .vault((vault) => vault.id.serialNumber)
  .toString();

export const deviceQuery = deviceModelPrimitives
  .site((s) => s.id.name)
  .stats(() => statsModelPrimitives)
  .history((history) => history.id.status.latitude.longitude.start)
  .events((event) => event.id.type.timestamp.telitTimestamp.latitude.longitude)
  .toString();

export const eventQuery = eventModelPrimitives.toString();

export const userQuery = userModelPrimitives.sites((s) => s.name).toString();

export const vaultQuery = vaultModelPrimitives
  .site((s) => s.name)
  .events((event) => event.type.timestamp.telitTimestamp)
  .lastLogin((user) => user.firstName.lastName.username)
  .loginHistory((history) => history.timestamp.user((u) => u.firstName.lastName.username))
  .toString();
