import { css } from '@emotion/react';
import { NotificationLevels, useAppStore } from '@ir/client/store';
import { useDashboardTranslation } from '@ir/client/translations/use-dashboard-translation';
import { Box, Button, LinearProgress } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AlertBell } from '../notifications/AlertBell';
import './styles.css';
import { IconMap } from '@ir/client/components/Icons/IconMap';
import { useEffect } from 'react';
import { createNotification } from '@ir/client/views/notifications';
import type { NOTIFICATION_TYPE } from 'react-notifications-component';
import { darkTheme } from '@ir/client/types/theme';

const views = [
  { view: 'map', icon: IconMap.map.icon.component, link: [`/map/cameras`, '/map/vehicles'] },
  { view: 'devices', icon: IconMap.devices.icon.component, link: '/devices' },
  { view: 'notifications', icon: IconMap.alerts.icon.component, link: '/notifications' },
] as const;

const LinkButton = Button;

const useStyles = makeStyles((theme: any) => ({
  navBar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    height: '72px',
    width: '100vw',
    flex: '0 0 72px',
    fontFamily: theme.typography.fontFamily,
  },
}));

export const DashboardNav = observer(() => {
  const store = useAppStore();
  const _history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { t } = useDashboardTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <nav className={classes.navBar}>
        <div className="nav-buttons">
          <div className="left">
            <AlertBell />
          </div>
          {views.map(({ icon: Icon, link, view }) => {
            const disabled = view === 'notifications' && store.auth.dashboardNotifications === NotificationLevels.Off;
            if (disabled) return null;
            return (
              <LinkButton
                component={Link as any}
                key={view}
                css={css`
                &:hover {
                  cursor: ${disabled ? 'not-allowed' : 'pointer'};
                  background: ${disabled ? 'transparent !important' : '#fff'};
                }
              `}
                to={Array.isArray(link) ? link[0] : link}
                className={`nav-button${
                  (
                    Array.isArray(link)
                      ? link.some((l) => location.pathname?.startsWith(l))
                      : location.pathname?.startsWith(link)
                  )
                    ? ' active'
                    : ''
                }`}
                cy-test={view}
              >
                <Icon style={{ marginRight: '0.5rem' }} />
                {t(view as any)}
              </LinkButton>
            );
          })}
          <div className="nav-button right">
            <Button cy-test="user" color="secondary">
              {IconMap.user.icon.s32}
              {store.auth.username}
            </Button>
            <Button cy-test="logout" color="secondary" onClick={() => store.auth.logOut()}>
              {IconMap.user.logout.s32}
              {t('logout')}
            </Button>
            {store.auth.isAdmin && (
              <LinkButton
                cy-test="settings"
                component={Link as any}
                className={`admin-button${location.pathname.startsWith('/admin') ? ' active' : ''}`}
                to="/admin/users"
              >
                <Settings />
              </LinkButton>
            )}
          </div>
        </div>
      </nav>
      <PageLoadingIndicator />
    </Box>
  );
});

const PageLoadingIndicator = observer(() => {
  const store = useAppStore();
  const classes = useProgressClasses({ isSuccess: store.pageLoader.loadingStatus === 'success' });
  useEffect(() => {
    const loadingStatus = store.pageLoader.loadingStatus;
    if (loadingStatus === 'error') {
      const loadingState = store.pageLoader.loadingState;
      createNotification({
        id: window.crypto.randomUUID(),
        message: loadingState.error?.toString() || loadingState.data.toString(),
        type: 'error' as NOTIFICATION_TYPE,
      });
    }

    if (store.pageLoader.loadingStatus === 'success') {
      setTimeout(() => {
        store.pageLoader.setLoadingStatus('idle');
        store.pageLoader.setLoadingState(undefined);
      }, 2000);
    }
  }, [store.pageLoader.loadingStatus]);

  if (store.pageLoader.isLoading || store.pageLoader.loadingStatus === 'success') {
    return <LinearProgress classes={classes} />;
  }

  return null;
});

const useProgressClasses = makeStyles({
  root: {
    width: '100vw',
  },
  colorPrimary: {
    backgroundColor: (props: { isSuccess: boolean }) =>
      props.isSuccess ? darkTheme.palette.success.main : darkTheme.palette.primary.main,
  },
  bar: {
    backgroundColor: (props: { isSuccess: boolean }) =>
      props.isSuccess ? darkTheme.palette.success.main : darkTheme.palette.primary.main,
  },
});
