import { ExtendedModel, model } from 'mobx-keystone';
import { DeviceModelBase } from './DeviceModel.base';
import { stateToStatus } from '@ir/client/components/Device/status-helpers';

/* A graphql query fragment builders for DeviceModel */
export { selectFromDevice, deviceModelPrimitives, DeviceModelSelector } from './DeviceModel.base';

/**
 * DeviceModel
 */
@model('Device')
export class DeviceModel extends ExtendedModel(DeviceModelBase, {}) {
  get statusConfig() {
    return this.status ? stateToStatus(this.status) : undefined;
  }
}
