/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { types } from 'mobx-keystone';

/**
 * Typescript enum
 */

export enum RoleLevels {
  Admin = 'Admin',
  Basic = 'Basic',
  Debug = 'Debug',
  Owner = 'Owner',
  User = 'User',
}

/**
 * RoleLevels
 *
 * Role Levels
 */
export const RoleLevelsEnumType = types.enum(RoleLevels);
