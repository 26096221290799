import { ExtendedModel, model } from 'mobx-keystone';
import { AlertCountsModelBase } from './AlertCountsModel.base';

/* A graphql query fragment builders for AlertCountsModel */
export { selectFromAlertCounts, alertCountsModelPrimitives, AlertCountsModelSelector } from './AlertCountsModel.base';

/**
 * AlertCountsModel
 */
@model('AlertCounts')
export class AlertCountsModel extends ExtendedModel(AlertCountsModelBase, {}) {}
