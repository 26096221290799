export const testNotifications = (email: string, phone: string) =>
  fetch(`${import.meta.env.DEV ? 'http://localhost:7979' : window.location.origin}/api/test_notification`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      phone,
    }),
  });

export const checkUserExists = (username) =>
  fetch(`${import.meta.env.DEV ? 'http://localhost:7979' : window.location.origin}/api/check_user`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username }),
  });
