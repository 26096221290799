import { ExtendedModel, model } from 'mobx-keystone';
import { AlertCountModelBase } from './AlertCountModel.base';

/* A graphql query fragment builders for AlertCountModel */
export { selectFromAlertCount, alertCountModelPrimitives, AlertCountModelSelector } from './AlertCountModel.base';

/**
 * AlertCountModel
 */
@model('AlertCount')
export class AlertCountModel extends ExtendedModel(AlertCountModelBase, {}) {}
