import { ExtendedModel, model } from 'mobx-keystone';
import { DeviceHistoryModelBase } from './DeviceHistoryModel.base';

/* A graphql query fragment builders for DeviceHistoryModel */
export {
  selectFromDeviceHistory,
  deviceHistoryModelPrimitives,
  DeviceHistoryModelSelector,
} from './DeviceHistoryModel.base';

/**
 * DeviceHistoryModel
 */
@model('DeviceHistory')
export class DeviceHistoryModel extends ExtendedModel(DeviceHistoryModelBase, {}) {}
