import './styles.css';
import type { PropsWithChildren } from 'react';

export function Loading(props: PropsWithChildren<any>) {
  return (
    <div id="loading">
      <div className="loading">
        <div className="indicator indicator1" />
        <div className="indicator indicator2" />
        <div className="indicator indicator3" />
        <div className="indicator indicator4" />
      </div>
      {props.children}
    </div>
  );
}
