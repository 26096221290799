import { Dialog, DialogContent, ThemeProvider } from '@material-ui/core';
import type { CSSProperties, MouseEvent, MouseEventHandler, PropsWithChildren } from 'react';
import { useCallback } from 'react';

import './styles.css';
import { createThemeWithOverrides } from '@ir/client/types/theme';

interface ModalProps extends PropsWithChildren<{}> {
  isOpen?: boolean;
  onClose?: MouseEventHandler<HTMLElement>;
  shouldCloseOnOverlayClick?: boolean;
  contentStyle?: CSSProperties;
}

const theme = createThemeWithOverrides({
  palette: {
    type: 'light',
  },
});

export function Modal({ isOpen = true, onClose, shouldCloseOnOverlayClick = true, children }: ModalProps) {
  const handleOverlayClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (shouldCloseOnOverlayClick && onClose) {
        onClose(e);
      }
    },
    [shouldCloseOnOverlayClick, onClose]
  );

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={handleOverlayClick} className="modal-container">
        <DialogContent className="modal-content">{children}</DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
