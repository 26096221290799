import { ExtendedModel, getRoot, model } from 'mobx-keystone';
import type { DeviceModel } from './DeviceModel';
import { EventModelBase } from './EventModel.base';
import type { AppStore } from '../root';

/* A graphql query fragment builders for EventModel */
export { selectFromEvent, eventModelPrimitives, EventModelSelector } from './EventModel.base';

/**
 * EventModel
 */
@model('Event')
export class EventModel extends ExtendedModel(EventModelBase, {}) {
  get device(): DeviceModel | undefined {
    const parent = getRoot<AppStore>(this);
    return this.deviceId ? parent.graphql.devices?.get(String(this.deviceId)) : undefined;
  }
}
