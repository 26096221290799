import { ExtendedModel, getRoot, model } from 'mobx-keystone';
import type { VaultModel } from './VaultModel';
import { VaultEventModelBase } from './VaultEventModel.base';
import type { AppStore } from '../root';

/* A graphql query fragment builders for VaultEventModel */
export { selectFromVaultEvent, vaultEventModelPrimitives, VaultEventModelSelector } from './VaultEventModel.base';

/**
 * VaultEventModel
 */
@model('VaultEvent')
export class VaultEventModel extends ExtendedModel(VaultEventModelBase, {}) {
  get vault(): VaultModel | undefined {
    const parent = getRoot<AppStore>(this);
    return this.vaultId ? parent?.graphql?.vaults.get(String(this.vaultId)) : undefined;
  }
}
