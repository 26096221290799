import { useAppStore } from '@ir/client/store';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { useQuery } from 'react-query';
import { createDeviceNotification } from './index';
import 'react-notifications-component/dist/theme.css';

const ReactNotification = ReactNotifications;
const alertSeenKey = 'alert-last-seen';
export const NotificationsHandler = observer((props: any) => {
  const store = useAppStore();

  const { isFetched, isSuccess, isFetching, data } = useQuery(
    'alerts',
    async () =>
      await store.graphql.queryAlerts({
        startTime: localStorage.getItem(alertSeenKey)
          ? moment.utc(localStorage.getItem(alertSeenKey)).toDate()
          : moment.utc().toDate(),
      }),
    {
      refetchInterval: 60000,
      enabled: !!store.auth.dashboardNotifications,
    }
  );
  useEffect(() => {
    if (isSuccess && !isFetching && data?.alerts?.length) {
      localStorage.setItem('alert-last-seen', data.alerts[0].timestamp);
      data?.alerts?.map(createDeviceNotification);
    }
  }, [isFetched, isFetching]);

  return (
    <>
      <ReactNotification />
      {props.children}
    </>
  );
});
