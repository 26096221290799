import { ExtendedModel, model } from 'mobx-keystone';
import { AlertModelBase } from './AlertModel.base';

/* A graphql query fragment builders for AlertModel */
export { selectFromAlert, alertModelPrimitives, AlertModelSelector } from './AlertModel.base';

/**
 * AlertModel
 */
@model('Alert')
export class AlertModel extends ExtendedModel(AlertModelBase, {}) {}
