import { siteModelPrimitives, useAppStore } from '@ir/client/store';
import { useDashboardTranslation } from '@ir/client/translations/use-dashboard-translation';
import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';

interface SiteListProps {
  checked: Array<number>;
  setChecked: (checked: Array<number> | undefined) => void;
}

export const SiteList = observer((props: SiteListProps) => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();
  const { data } = useQuery('sites', async () => await store.graphql.querySites({}, siteModelPrimitives.toString()));
  const handleToggle = (value) => {
    const isSelected = props.checked.includes(value);
    if (isSelected) {
      props.setChecked(props.checked.filter((id) => id !== value));
    } else {
      props.setChecked([...props.checked, value]);
    }
  };
  return (
    <>
      <Typography variant="subtitle1">{`${t('sites')}`}</Typography>
      <List style={{ maxHeight: '175px', overflowY: 'scroll' }}>
        {data.sites.map((value) => {
          const siteId = Number(value.id);
          const labelId = `checkbox-list-label-${value.id}`;
          return (
            <ListItem key={siteId} dense button style={{ maxHeight: '35px' }} onClick={() => handleToggle(siteId)}>
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  checked={props.checked.indexOf(siteId) !== -1}
                  tabIndex={-1}
                  disableRipple={true}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
});
