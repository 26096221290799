import { Loading } from '@ir/client/components/Loader';
import { Suspense, lazy, useEffect, type LazyExoticComponent, type FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

function NotFound() {
  return <h1>404.. Page not found!</h1>;
}

export enum MapViews {
  Cameras = 'cameras',
  Vehicles = 'vehicles',
}

export enum AdminViews {
  Users = 'users',
  Sites = 'sites',
  Devices = 'devices',
  Vaults = 'vaults',
  VaultMessages = 'vault-messages',
  DeviceMessages = 'device-messages',
  RequestList = 'request-list',
}

export enum Views {
  Map = 'map',
  Devices = 'devices',
  Notifications = 'notifications',
  Admin = 'admin',
}

export function formatPath(key: string, paths?: Array<string>) {
  return `/:${key}${paths ? `(${paths.join('|')})` : ''}`;
}

function CreateComponent<Comp extends LazyExoticComponent<FC<any>>>(Component: Comp) {
  return (props: Parameters<Comp>[0]) => {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

const MapRoute = CreateComponent(lazy(() => import('./views/map/index')));
const DevicesRoute = CreateComponent(lazy(() => import('./views/devices/index')));
const NotificationsRoute = CreateComponent(lazy(() => import('./views/notifications/index')));
const AdminRoute = CreateComponent(lazy(() => import('./views/admin/index')));

export const Debug = CreateComponent(lazy(() => import('./views/admin/pages/Debug')));
export const Device = CreateComponent(lazy(() => import('./views/admin/pages/Device')));
export const Sites = CreateComponent(lazy(() => import('./views/admin/pages/Sites')));
export const VaultDebug = CreateComponent(lazy(() => import('./views/admin/pages/VaultDebug')));
export const Vaults = CreateComponent(lazy(() => import('./views/admin/pages/Vaults')));
export const Users = CreateComponent(lazy(() => import('./views/admin/pages/User/index')));
/**
 * Creates a full path with a match param key and path keys (e.g. [{view: ['map']}] turns into /:view(map))
 * @param paths
 */
export function stringifyPath(paths: Record<string, Array<string>>): string {
  return Object.entries(paths)
    .map(([key, value]) => formatPath(key, value))
    .join('');
}

export function Router() {
  const history = useHistory();
  useEffect(() => {
    if (history.location?.pathname === '/' || history.location?.pathname === '/map') {
      history.push(`/map/${MapViews.Cameras}`);
    }
  }, [history.location?.pathname]);
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route
          path={stringifyPath({
            view: [Views.Map],
            viewType: [MapViews.Cameras, MapViews.Vehicles],
          })}
          component={MapRoute}
        />
        <Route
          path={stringifyPath({
            view: [Views.Admin],
            viewType: Object.values(AdminViews),
          })}
          component={AdminRoute}
        />
        <Route
          path={stringifyPath({
            view: [Views.Devices],
          })}
          component={DevicesRoute}
        />
        <Route
          path={stringifyPath({
            view: [Views.Notifications],
          })}
          component={NotificationsRoute}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
}
