import type { AlertModel } from '@ir/client/store';
import { AlertCriticality, alertCountsModelPrimitives, useAppStore } from '@ir/client/store';
import { useDashboardTranslation } from '@ir/client/translations/use-dashboard-translation';
import { darkTheme } from '@ir/client/types/theme';
import { Button, Chip, Dialog, DialogActions, DialogContent, Divider, Grid, Typography } from '@material-ui/core';
import { observer, useLocalObservable } from 'mobx-react-lite';
import moment from 'moment';
import type { FC, PropsWithChildren } from 'react';
import { type iNotification, type NOTIFICATION_TYPE, Store as notificationStore } from 'react-notifications-component';
import { useQuery } from 'react-query';
import { Virtuoso } from 'react-virtuoso';
import { AlertCard } from './AlertCard';
import { SortCard } from './SortCard';
import { ThemeProvider } from '@material-ui/core/styles';
interface NotificationsProps extends PropsWithChildren<{}> {}

export const notificationDefaults = (data: AlertModel) =>
  ({
    id: `${data.id}`,
    title: `${data.vault?.current?.serialNumber || data.device?.current?.serialNumber} - ${data.type}`,
    message: `${new Date(data.timestamp).toLocaleDateString()} ${new Date(data.timestamp).toLocaleTimeString()}`,
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  }) satisfies iNotification;

export const createNotification = ({
  id = Date.now(),
  title = '',
  message = ' ',
  type,
}: {
  id?: string | number;
  title?: string;
  message?: string;
  type: NOTIFICATION_TYPE;
}) =>
  notificationStore.addNotification({
    id: `${id}`,
    type,
    title,
    message,
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
export const createDeviceNotification = (data: AlertModel) => {
  switch (data.criticality) {
    case 'Info':
      notificationStore.addNotification({ type: 'success', ...notificationDefaults(data) });
      break;
    case 'Warning':
      notificationStore.addNotification({
        type: data.criticality.toLowerCase() as NOTIFICATION_TYPE,
        ...notificationDefaults(data),
      });
      break;
    case 'Alarm':
      notificationStore.addNotification({ type: 'danger', ...notificationDefaults(data) });
      break;
    default:
      break;
  }
};
const alertTypes = [AlertCriticality.Alarm, AlertCriticality.Warning, AlertCriticality.Info];

const Notifications: FC<NotificationsProps> = observer(() => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();
  const { data, refetch } = useQuery(
    'alertCounts',
    async () =>
      await store.graphql.queryAlertCount(
        {},
        alertCountsModelPrimitives
          .Alarm((a) => a.complete.incomplete)
          .Warning((a) => a.complete.incomplete)
          .Info((a) => a.complete.incomplete)
          .toString()
      )
  );
  const state = useLocalObservable(() => ({
    openConfirm: undefined,
    handleConfirmOpen(type: AlertCriticality) {
      this.openConfirm = type;
    },
    handleConfirmClose() {
      this.openConfirm = undefined;
    },
    async handleUpdateCheck() {
      const user = Array.from(store.graphql.users.values()).find((u) => u.username === store.auth.username);
      if (user) {
        await Promise.allSettled(
          state.filterAlerts('incomplete', state.openConfirm).map((alert) =>
            store.graphql.mutateSetHandledBy({
              alertId: alert.id,
              userId: user.id,
            })
          )
        );
        refetch();
      }
      this.openConfirm = undefined;
    },
    ranges: {
      [AlertCriticality.Alarm]: {
        startIndex: 0,
        endIndex: 0,
      },
      [AlertCriticality.Warning]: {
        startIndex: 0,
        endIndex: 0,
      },
      [AlertCriticality.Info]: {
        startIndex: 0,
        endIndex: 0,
      },
    },
    orderBy: 'timestamp' as string,
    updateOrderBy: (field: string) => {
      state.orderBy = field;
    },
    filterBy: 'incomplete' as string,
    updateFilterBy: (field: string) => {
      state.filterBy = field;
    },
    loadAlerts(type: AlertCriticality) {
      return Array.from(store.graphql.alerts.values()).filter((d) => d.criticality === type);
    },
    get loadUsers() {
      return Array.from(store.graphql.users.values(), (user) => user.username);
    },
    sortAlerts: (alertModels: AlertModel[]) => {
      if (state.orderBy === 'description') {
        return alertModels.sort((a, b) => a.type?.localeCompare(b.type!)!);
      } else if (state.orderBy === 'timestamp') {
        return alertModels.sort((a, b) => (moment(a.timestamp).isBefore(moment(b.timestamp)) ? 1 : -1));
      } else {
        return alertModels.sort((a, b) =>
          a.device?.id && b.device?.id
            ? ((a.device.id as unknown as number) || 0) - ((b.device.id as unknown as number) || 0)
            : -1
        );
      }
    },
    filterAlerts: (type: string, criticality: AlertCriticality) => {
      if (type === 'complete') {
        return state.sortAlerts(state.loadAlerts(criticality)).filter((d) => d.isHandled === true);
      } else if (type === 'incomplete') {
        return state.sortAlerts(state.loadAlerts(criticality)).filter((d) => d.isHandled !== true);
      } else {
        return state.sortAlerts(state.loadAlerts(criticality));
      }
    },
  }));
  useQuery('users', () => store.graphql.queryUsers());
  useQuery(
    ['alerts', AlertCriticality.Alarm, Math.floor((state.ranges[AlertCriticality.Alarm].endIndex + 5) / 100)],
    async () =>
      await store.graphql.queryAlerts({
        alertCriticality: AlertCriticality.Alarm,
        offset: Math.floor((state.ranges[AlertCriticality.Alarm].endIndex + 5) / 100),
      }),
    {
      refetchInterval: 15000,
    }
  );
  useQuery(
    ['alerts', AlertCriticality.Warning, Math.floor((state.ranges[AlertCriticality.Warning].endIndex + 5) / 100)],
    async () =>
      await store.graphql.queryAlerts({
        alertCriticality: AlertCriticality.Warning,
        offset: Math.floor((state.ranges[AlertCriticality.Warning].endIndex + 5) / 100),
      }),
    {
      refetchInterval: 15000,
    }
  );
  useQuery(
    ['alerts', AlertCriticality.Info, Math.floor((state.ranges[AlertCriticality.Info].endIndex + 5) / 100)],
    async () =>
      await store.graphql.queryAlerts({
        alertCriticality: AlertCriticality.Info,
        offset: Math.floor((state.ranges[AlertCriticality.Info].endIndex + 5) / 100),
      }),
    {
      refetchInterval: 15000,
    }
  );
  return (
    <ThemeProvider theme={darkTheme}>
      <div>
        <div
          style={{
            // backgroundColor: '#fff',
            padding: '5px',
            height: 'calc(100vh - 72px)',
            width: '100vw',
            overflowY: 'auto',
          }}
        >
          <div>
            <SortCard
              orderBy={state.orderBy}
              filterBy={state.filterBy}
              updateSort={state.updateOrderBy}
              updateFilter={state.updateFilterBy}
            />
          </div>
          <Grid
            container={true}
            direction="row"
            spacing={2}
            justifyContent="space-between"
            style={{ padding: '12px', height: 'calc(100vh - 75px - 100px)' }}
          >
            {alertTypes.map((type) => {
              const count =
                state.filterBy && state.filterBy !== 'all'
                  ? data?.alertCount?.[type]?.[state.filterBy]
                  : data?.alertCount?.[type]?.incomplete + data?.alertCount?.[type]?.complete || 0;
              return (
                <Grid
                  container={true}
                  item={true}
                  key={type}
                  direction="column"
                  xs={4}
                  spacing={1}
                  style={{
                    backgroundColor: '#7d7d7d',
                    borderRadius: '5px',
                  }}
                >
                  <div style={{ padding: '5px', height: '3rem' }}>
                    <div
                      style={{
                        color: 'white',
                        fontSize: '20px',
                        paddingBottom: '5px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>{`${t(type.toLowerCase() as any)}`}</div>

                      <Chip
                        label={count}
                        disabled={!count || state.filterBy === 'complete'}
                        onClick={() => state.handleConfirmOpen(type)}
                      />
                    </div>
                    <Divider />
                  </div>
                  <div style={{ height: 'calc(100% - 3rem)' }}>
                    <Virtuoso
                      rangeChanged={(range) => {
                        state.ranges[type] = range;
                      }}
                      style={{ flex: 1 }}
                      data={state.filterAlerts(state.filterBy, type)}
                      itemContent={(_i, alert: AlertModel) => (
                        <AlertCard key={alert.id} refetch={refetch} data={alert} />
                      )}
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <Dialog open={!!state.openConfirm} onClose={state.handleConfirmClose} fullWidth={true} maxWidth="sm">
            <DialogContent>
              <Typography variant="h6">{t('set-all-notifications-as-complete')}?</Typography>
            </DialogContent>
            <DialogActions>
              <Button style={{ color: 'white' }} onClick={state.handleConfirmClose} color="inherit">
                {t('Cancel')}
              </Button>
              <Button style={{ color: 'white' }} onClick={state.handleUpdateCheck} color="inherit">
                {t('Complete')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </ThemeProvider>
  );
});

// eslint-disable-next-line import/no-default-export
export default Notifications;
