/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { prop, tProp, Model } from 'mobx-keystone';
import { QueryBuilder } from 'mk-gql';

/**
 * VaultEventBase
 * auto generated base class for the model VaultEventModel.
 */
export class VaultEventModelBase extends Model({
  __typename: tProp('VaultEvent'),
  id: prop<number>().withSetter(),
  power: prop<number | null>().withSetter(),
  telitTimestamp: prop<any>().withSetter(),
  temperature: prop<number | null>().withSetter(),
  timestamp: prop<any | null>().withSetter(),
  type: prop<string | null>().withSetter(),
  value: prop<string | null>().withSetter(),
  vaultId: prop<number>().withSetter(),
}) {
  getRefId() {
    return String(this.id);
  }
}

export class VaultEventModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`);
  }
  get power() {
    return this.__attr(`power`);
  }
  get telitTimestamp() {
    return this.__attr(`telitTimestamp`);
  }
  get temperature() {
    return this.__attr(`temperature`);
  }
  get timestamp() {
    return this.__attr(`timestamp`);
  }
  get type() {
    return this.__attr(`type`);
  }
  get value() {
    return this.__attr(`value`);
  }
  get vaultId() {
    return this.__attr(`vaultId`);
  }
}
export function selectFromVaultEvent() {
  return new VaultEventModelSelector();
}

export const vaultEventModelPrimitives =
  selectFromVaultEvent().power.telitTimestamp.temperature.timestamp.type.value.vaultId;
