import type { Subtract } from '@ir/client/types';
import type { MaterialSymbolProps } from 'react-material-symbols';

type IconMap<Depth extends number> = Depth extends 0
  ? never
  : { [k: string]: MaterialSymbolProps['icon'] | IconMap<Subtract<Depth, 1>> };
export type SymbolMapping = IconMap<5>;

export const DeviceSymbols = {
  icon: 'deployed_code',
  camera: 'service_toolbox',
  vehicle: 'local_shipping',
  box: 'hard_drive', // Use vehicle for box
  vault: {
    icon: 'account_balance_wallet',
    eventHistory: 'browse_activity',
  },
  location: 'universal_local',
  firmwareVersion: 'deployed_code',
  eventHistory: 'deployed_code_history',
  status: {
    icon: 'monitor_heart',
    docked: 'nearby',
    inUse: 'adjust',
    alarm: 'shield_question',
    critical: 'tamper_detection_off',
  },
} as const satisfies SymbolMapping;

export const SymbolNames = {
  dashboard: 'developer_board',
  map: {
    icon: 'travel_explore',
    layers: 'layers',
    fitBounds: 'crop_free',
  },
  timeline: 'timeline',
  filter: 'category',
  stats: 'data_exploration',
  date: {
    currentDateTime: 'calendar_clock',
  },
  user: {
    icon: 'person',
    users: 'recent_actors',
    logout: 'logout',
  },
  alerts: {
    icon: 'notifications',
    off: 'notifications_off',
    on: 'notifications_active',
    unread: 'notifications_unread',
  },
  events: {
    icon: 'blood_pressure',
    // events
    type: 'interests',
  },
  devices: DeviceSymbols,
} as const satisfies SymbolMapping;
