/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { prop, tProp, Model, type Ref } from 'mobx-keystone';
import { QueryBuilder } from 'mk-gql';
import type { DeviceModel } from './DeviceModel';

import { DeviceModelSelector } from './DeviceModel';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  device: DeviceModel;
};

/**
 * DeviceHistoryBase
 * auto generated base class for the model DeviceHistoryModel.
 */
export class DeviceHistoryModelBase extends Model({
  __typename: tProp('DeviceHistory'),
  device: prop<Ref<DeviceModel> | null>().withSetter(),
  id: prop<number>().withSetter(),
  latitude: prop<number | null>().withSetter(),
  longitude: prop<number | null>().withSetter(),
  start: prop<any | null>().withSetter(),
  status: prop<string | null>().withSetter(),
}) {
  getRefId() {
    return String(this.id);
  }
}

export class DeviceHistoryModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`);
  }
  get latitude() {
    return this.__attr(`latitude`);
  }
  get longitude() {
    return this.__attr(`longitude`);
  }
  get start() {
    return this.__attr(`start`);
  }
  get status() {
    return this.__attr(`status`);
  }
  device(builder?: string | DeviceModelSelector | ((selector: DeviceModelSelector) => DeviceModelSelector)) {
    return this.__child(`device`, DeviceModelSelector, builder);
  }
}
export function selectFromDeviceHistory() {
  return new DeviceHistoryModelSelector();
}

export const deviceHistoryModelPrimitives = selectFromDeviceHistory().latitude.longitude.start.status;
