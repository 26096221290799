import type { PropsWithChildren } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { emailMask } from 'text-mask-addons';
import { IMaskInput } from 'react-imask';

export const placeholderChars = {
  whitespace: '\u2000',
  underscore: '_',
};

const maskOptions = {
  date: {
    name: 'Date',
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    placeholder: '25/09/1970',
    guide: true,
  },
  email: {
    name: 'Email',
    mask: emailMask,
    placeholder: 'john@smith.com',
    guide: true,
  },
  pin: {
    name: 'pin',
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    guide: false,
  },
  latitude: {
    mask: '[-][#]0.0000000',
    definitions: {
      '#': /\d/,
      '-': /(-)/,
    },
    signed: false,
  },
  longitude: {
    mask: '[-][#][!]0.0000000',
    definitions: {
      '#': /[01]/,
      '!': /[0-8]/,
      '-': /(-)/,
    },
  },
};

export function MaskComponent(props: any) {
  const { component: Component, inputRef, ...other }: any = props;

  useImperativeHandle(inputRef, () => ({
    focus: () => {
      //
    },
  }));

  // @ts-ignore
  return <Component {...other} mask={maskOptions[other.type].mask} type="text" guide={maskOptions[other.type].guide} />;
}

export const TextMaskCustom = forwardRef<HTMLElement, any>(
  // eslint-disable-next-line react/prop-types
  ({ onChange, mask, type, ...other }: PropsWithChildren<any>, ref) => (
    <IMaskInput
      {...other}
      {...maskOptions[type]}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: other.name, value } })}
      overwrite
    />
  )
);
