/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import type { IObservableArray } from 'mobx';
import { prop, tProp, Model, type Ref } from 'mobx-keystone';
import { QueryBuilder } from 'mk-gql';
import type { NotificationLevels } from './NotificationLevelsEnum';
import type { RoleLevels } from './RoleLevelsEnum';
import type { SiteModel } from './SiteModel';

import { SiteModelSelector } from './SiteModel';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  sites: IObservableArray<SiteModel>;
};

/**
 * UserBase
 * auto generated base class for the model UserModel.
 */
export class UserModelBase extends Model({
  __typename: tProp('User'),
  dashboardNotifications: prop<NotificationLevels | null>().withSetter(),
  email: prop<string | null>().withSetter(),
  emailNotifications: prop<NotificationLevels | null>().withSetter(),
  firstName: prop<string | null>().withSetter(),
  id: prop<string>().withSetter(),
  lastModified: prop<any | null>().withSetter(),
  lastName: prop<string | null>().withSetter(),
  middleName: prop<string | null>().withSetter(),
  notifications: prop<boolean | null>().withSetter(),
  phone: prop<string | null>().withSetter(),
  role: prop<RoleLevels | null>().withSetter(),
  secondaryEmail: prop<string | null>().withSetter(),
  secondaryPhone: prop<string | null>().withSetter(),
  sites: prop<Ref<SiteModel>[] | null>(() => []).withSetter(),
  textNotifications: prop<NotificationLevels | null>().withSetter(),
  twofaEnabled: prop<boolean | null>().withSetter(),
  username: prop<string | null>().withSetter(),
  vaultPin: prop<string | null>().withSetter(),
}) {
  getRefId() {
    return String(this.id);
  }
}

export class UserModelSelector extends QueryBuilder {
  get dashboardNotifications() {
    return this.__attr(`dashboardNotifications`);
  }
  get email() {
    return this.__attr(`email`);
  }
  get emailNotifications() {
    return this.__attr(`emailNotifications`);
  }
  get firstName() {
    return this.__attr(`firstName`);
  }
  get id() {
    return this.__attr(`id`);
  }
  get lastModified() {
    return this.__attr(`lastModified`);
  }
  get lastName() {
    return this.__attr(`lastName`);
  }
  get middleName() {
    return this.__attr(`middleName`);
  }
  get notifications() {
    return this.__attr(`notifications`);
  }
  get phone() {
    return this.__attr(`phone`);
  }
  get role() {
    return this.__attr(`role`);
  }
  get secondaryEmail() {
    return this.__attr(`secondaryEmail`);
  }
  get secondaryPhone() {
    return this.__attr(`secondaryPhone`);
  }
  get textNotifications() {
    return this.__attr(`textNotifications`);
  }
  get twofaEnabled() {
    return this.__attr(`twofaEnabled`);
  }
  get username() {
    return this.__attr(`username`);
  }
  get vaultPin() {
    return this.__attr(`vaultPin`);
  }
  sites(builder?: string | SiteModelSelector | ((selector: SiteModelSelector) => SiteModelSelector)) {
    return this.__child(`sites`, SiteModelSelector, builder);
  }
}
export function selectFromUser() {
  return new UserModelSelector();
}

export const userModelPrimitives =
  selectFromUser().dashboardNotifications.email.emailNotifications.firstName.lastModified.lastName.middleName
    .notifications.phone.role.secondaryEmail.secondaryPhone.textNotifications.twofaEnabled.username.vaultPin;
