/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { types } from 'mobx-keystone';

/**
 * Typescript enum
 */

export enum AlertCriticality {
  Alarm = 'Alarm',
  Info = 'Info',
  Warning = 'Warning',
}

/**
 * AlertCriticality
 *
 * Alert Criticality
 */
export const AlertCriticalityEnumType = types.enum(AlertCriticality);
