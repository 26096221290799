import { ExtendedModel, model } from 'mobx-keystone';
import { StatsModelBase } from './StatsModel.base';

/* A graphql query fragment builders for StatsModel */
export { selectFromStats, statsModelPrimitives, StatsModelSelector } from './StatsModel.base';

/**
 * StatsModel
 */
@model('Stats')
export class StatsModel extends ExtendedModel(StatsModelBase, {}) {}
