import { ExtendedModel, model } from 'mobx-keystone';
import { SiteModelBase } from './SiteModel.base';

/* A graphql query fragment builders for SiteModel */
export { selectFromSite, siteModelPrimitives, SiteModelSelector } from './SiteModel.base';

/**
 * SiteModel
 */
@model('Site')
export class SiteModel extends ExtendedModel(SiteModelBase, {}) {}
