import { ExtendedModel, model } from 'mobx-keystone';
import { LoginHistoryModelBase } from './LoginHistoryModel.base';

/* A graphql query fragment builders for LoginHistoryModel */
export {
  selectFromLoginHistory,
  loginHistoryModelPrimitives,
  LoginHistoryModelSelector,
} from './LoginHistoryModel.base';

/**
 * LoginHistoryModel
 */
@model('LoginHistory')
export class LoginHistoryModel extends ExtendedModel(LoginHistoryModelBase, {}) {}
