import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardHeader, Grid } from '@material-ui/core';

export function ErrorPage(props: { error: string; errorInfo: string }) {
  const history = useHistory();

  function updateClipboard() {
    window.navigator.clipboard.writeText(JSON.stringify({ error: props.error, errorInfo: props.errorInfo })).then(
      () => {
        /* clipboard successfully set */
      },
      () => {
        /* clipboard write failed */
      }
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: 'calc(100vh - 72px)', width: '100vw', background: '#272D36' }}
    >
      <Card elevation={3} style={{ padding: '2rem' }}>
        <CardHeader title="An error has occurred" />
        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/map', { search: history.location.search })}
          >
            Return to Homepage
          </Button>
          <Box style={{ display: 'flex', marginTop: '1rem' }}>
            <Button variant="contained" color="secondary" onClick={() => updateClipboard()}>
              Copy error to clipboard
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
