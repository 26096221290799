import { useDashboardTranslation } from '@ir/client/translations/use-dashboard-translation';
import type { Theme } from '@material-ui/core';
import { Box, Divider, FormControl, createStyles, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SortIcon from '@material-ui/icons/Sort';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { FC, PropsWithChildren } from 'react';

interface SortCardProps extends PropsWithChildren<{}> {
  updateSort: (field: string) => void;
  updateFilter: (field: string) => void;
  orderBy: string;
  filterBy: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      color: 'blue',
    },
    customSelect: {
      '& .MuiSelect-selectMenu': {
        color: '#c1c2c4',
        fontSize: '12px',
      },
    },
    toggle: {
      '& .MuiToggleButton-root': {
        color: '#c1c2c4',
        minHeight: '35px',
        maxHeight: '35px',
        fontSize: '11px',
      },
      '& .Mui-selected': {
        // backgroundColor: "#DCDCDC"
      },
    },
  })
);
export const SortCard: FC<SortCardProps> = observer((SortCardProps) => {
  const classes = useStyles();
  const { t } = useDashboardTranslation();
  const state = useLocalObservable(() => ({
    handleChange: (e) => {
      SortCardProps.updateSort(e.target.value);
    },
    handleFilter: (_e, newValue) => {
      SortCardProps.updateFilter(newValue);
    },
  }));

  return (
    <div style={{ paddingBottom: '5px' }}>
      <Card
        style={{
          // backgroundColor: '#424242',
          width: '100%',
          padding: '1rem',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box mr={2}>
          <SortIcon style={{ color: '#fff', fontSize: '2rem' }} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',

            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
          }}
        >
          <FormControl fullWidth={true} style={{ marginRight: '1rem' }}>
            <InputLabel
              id="order-by-select-label"
              style={{
                fontSize: '13px',
                paddingTop: '9px',
                paddingRight: '7px',
              }}
            >
              {t('sort-by')}
            </InputLabel>
            <Select
              labelId="order-by-select-label"
              className={classes.customSelect}
              value={SortCardProps.orderBy}
              onChange={state.handleChange}
            >
              <MenuItem value="serial number">{t('serial-number')}</MenuItem>
              <MenuItem value="timestamp">{t('time-of-alert')}</MenuItem>
              <MenuItem value="description">{t('cause-of-alert')}</MenuItem>
            </Select>
          </FormControl>
          <Divider orientation="vertical" flexItem={true} />
          <Box display="flex" flexDirection="column" ml={2}>
            <InputLabel
              id="filter-by-label"
              style={{
                // color: '#fff',
                fontSize: '13px',
                marginBottom: '2px',
                paddingLeft: '5px',
                paddingRight: '7px',
              }}
            >
              {t('filter-by')}
            </InputLabel>

            <ToggleButtonGroup
              className={classes.toggle}
              exclusive={true}
              value={SortCardProps.filterBy}
              onChange={state.handleFilter}
              size="small"
            >
              <ToggleButton value="all">{t('all')}</ToggleButton>
              <ToggleButton value="complete">{t('complete')}</ToggleButton>
              <ToggleButton value="incomplete">{t('incomplete')}</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Card>
    </div>
  );
});
