import { CustomMobxForm, isAlphanumeric, isLength, isNumeric, isRequired, plugins } from '@ir/client/components/form';
import { FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import type Form from 'mobx-react-form';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';

import { Modal } from '@ir/client/components/Modal';

import './styles.css';
import type { DeviceInput } from '@ir/client/store';
import { appStore, useAppStore } from '@ir/client/store';
import { useDashboardTranslation } from '@ir/client/translations/use-dashboard-translation';

const deviceOptions = [
  {
    id: 'camera',
    value: 'camera',
    text: 'Camera',
  },
  { id: 'pm-box', value: 'pm-box', text: 'Vehicle' },
];

const fields = {
  serialNumber: {
    name: 'serialNumber',
    label: 'Serial Number',
    validators: [isAlphanumeric, isLength({ min: 1, max: 46 })],
    bindings: 'MaterialTextField',
  },
  site: {
    name: 'site',
    label: 'Site',
    validators: [isNumeric, isRequired],
    bindings: 'MaterialSelectField',
  },
  deviceType: {
    name: 'deviceType',
    label: 'Device Type (required)',
    validators: [isAlphanumeric, isLength({ min: 1, max: 16 })],
    bindings: 'MaterialSelectField',
  },
  telitThingId: {
    name: 'telitThingId',
    label: 'Telit Thing ID (required)',
    validators: [isAlphanumeric, isLength({ min: 1, max: 46 })],
    bindings: 'MaterialTextField',
  },
  firmwareVersion: {
    name: 'firmwareVersion',
    label: 'Firmware Version',
    validators: [isAlphanumeric, isLength({ min: 0, max: 46 })],
    bindings: 'MaterialTextField',
  },
  manufacturer: {
    name: 'manufacturer',
    label: 'Manufacturer',
    validators: [isAlphanumeric, isLength({ min: 0, max: 46 })],
    bindings: 'MaterialTextField',
  },
};

const hooks = {
  onSuccess(form: Form) {
    // get field values
    appStore.graphql.mutateCreateDevice({ device: form.values() as DeviceInput }).then(() => {
      appStore.modal.hideModal();
      form.reset();
    });
  },
  onError(form: Form) {
    console.error('Error submitting form', form.errors());
  },
};
const myForm = new CustomMobxForm({ fields }, { plugins, hooks });

export const AddDevice = observer(() => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();

  useQuery('sites', () => store.graphql.querySites());
  return (
    <Modal isOpen={true} onClose={store.modal.hideModal}>
      <div className="admin-add-modal">
        <h3>{`${t('add')} ${t('device')}`}</h3>
        <form autoComplete="off" className="single-form" onSubmit={(e) => myForm.onSubmit(e as unknown as Event)}>
          <Grid container={true} justifyContent="center" alignItems="stretch" spacing={2}>
            <Grid item={true} xs={12}>
              <TextField {...myForm.$('serialNumber').bind({})} label={t(myForm.$('serialNumber').label)} />
            </Grid>
            <Grid item={true} xs={12} className="grid-item">
              <FormControl>
                <InputLabel shrink={true} htmlFor="site-simple">
                  {`${t('site')} ${t('(required)')}`}
                </InputLabel>
                <Select {...myForm.$('site').bind({})}>
                  {Array.from(store.graphql.sites.values(), (opt) => (
                    <MenuItem key={opt.id} value={opt.id} children={opt.name} />
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={12} className="grid-item">
              <FormControl>
                <InputLabel shrink={true} htmlFor="deviceType-simple">
                  {`${t('device')} ${t('type')} ${t('(required)')}`}
                </InputLabel>
                <Select {...myForm.$('deviceType').bind({})}>
                  {deviceOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value} children={`${t(opt.text as any)}`} />
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={12}>
              <TextField {...myForm.$('telitThingId').bind({})} label={t(myForm.$('telitThingId').label)} />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField {...myForm.$('firmwareVersion').bind({})} label={t(myForm.$('firmwareVersion').label)} />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField {...myForm.$('manufacturer').bind({})} label={t(myForm.$('manufacturer').label)} />
            </Grid>
            <Grid item={true} xs={12} style={{ marginTop: '15px' }}>
              <Button
                onClick={() => {
                  myForm.submit();
                }}
                disabled={!myForm.isValid}
                type="submit"
              >
                {`${t('submit')}`}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
});
