import { model, Model, modelAction, prop } from 'mobx-keystone';
import type { UseQueryResult, QueryStatus } from 'react-query';
import { useAppStore } from '@ir/client/store/Provider';
import { useEffect, useRef } from 'react';
import { computed, observable } from 'mobx';

@model('PageLoader')
export class PageLoader extends Model({
  loadingStatus: prop<QueryStatus>(() => 'idle').withSetter(),
}) {
  @observable loadingState: UseQueryResult | undefined = undefined;

  @modelAction setLoadingState(results: UseQueryResult) {
    this.loadingState = results;
  }

  @computed get isLoading() {
    return this.loadingStatus === 'loading';
  }
}

export const useListenToQuery = <Queries extends UseQueryResult[]>(...queries: Queries) => {
  const store = useAppStore();
  const isRunning = useRef(true);
  useEffect(() => {
    if (isRunning.current) {
      const allDone = queries.every((queryResult) => queryResult.status === 'success');
      if (allDone) {
        store.pageLoader.setLoadingState({ ...queries[0] });
        store.pageLoader.setLoadingStatus('success');
        isRunning.current = false;
      } else {
        const sortedQueries = queries
          .filter((q) => !q.isSuccess)
          .toSorted((a, _b) =>
            a.status === 'loading' ? -1 : a.status === 'error' ? 0 : a.status === 'success' ? 1 : 2
          );
        const overallState = sortedQueries[0];

        store.pageLoader.setLoadingState({ ...overallState });
        store.pageLoader.setLoadingStatus(overallState.status);
      }
    }
  }, [queries]);

  return store.pageLoader.loadingStatus;
};
