import '@fontsource-variable/roboto-flex';
import 'react-material-symbols/rounded';
import type { ThemeOptions } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import { merge } from 'lodash';
import type { PaletteOptions } from '@material-ui/core/styles/createPalette';

const fontFamily = '"Roboto Flex Variable", sans-serif';

const lightThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#32628D',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f7f9ff',
      contrastText: '#181c20',
    },
    background: {
      default: '#F7F9FF',
      paper: '#F7F9FF',
    },
    text: {
      primary: '#181C20',
      secondary: '#181C20',
    },
  } satisfies PaletteOptions,
  typography: {
    fontFamily,
  },
};
const themeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#9DCBFC',
      light: '#32628D',
      contrastText: '#003355',
    },
    secondary: {
      main: '#BAC8DA',
      contrastText: '#243240',
    },
    background: {
      default: '#101418',
      paper: '#1D2024',
      defaultTransparent: '#1014184A',
    },
    text: {
      primary: '#E0E2E8',
      secondary: '#E0E2E8',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
    },
  },
  typography: {
    fontFamily,
  },
  status: {
    docked: {
      name: 'Docked',
      background: '#42be65',
      dark: '#31b55c',
      gradient: 'linear-gradient(90deg, #31b55c 0%, #42be65 100%)',
      getGradient(opacity: string = 'FF') {
        return `linear-gradient(90deg, ${this.dark}${opacity} 0%, ${this.background}${opacity} 100%)`;
      },
    },
    inUse: {
      name: 'In Use',
      background: '#4589ff',
      dark: '#2874f9',
      gradient: 'linear-gradient(90deg, #2874f9 0%, #4589ff 100%)',
      getGradient(opacity: string = 'FF') {
        return `linear-gradient(90deg, ${this.dark}${opacity} 0%, ${this.background}${opacity} 100%)`;
      },
    },
    alarm: {
      name: 'Unknown',
      background: '#5E5E60',
      dark: '#525254',
      gradient: 'linear-gradient(90deg, #5E5E60 0%, #919093 100%)',
      getGradient(opacity: string = 'FF') {
        return `linear-gradient(90deg, ${this.dark}${opacity} 0%, ${this.background}${opacity} 100%)`;
      },
    },
    // alarm: {
    //   name: 'Alarm',
    //   background: '#f1873e',
    //   dark: '#e96516',
    //   gradient: 'linear-gradient(90deg, #e96516 0%, #f1873e 100%)',
    //   getGradient(opacity: string = 'FF') {
    //     return `linear-gradient(90deg, ${this.dark}${opacity} 0%, ${this.background}${opacity} 100%)`;
    //   },
    // },
    critical: {
      name: 'Critical',
      background: '#fa4d56',
      dark: '#f9343e',
      gradient: 'linear-gradient(90deg, #f9343e 0%, #fa4d56 100%)',
      getGradient(opacity: string = 'FF') {
        return `linear-gradient(90deg, ${this.dark}${opacity} 0%, ${this.background}${opacity} 100%)`;
      },
    },
  },
};

type IRTheme = typeof themeOptions;
declare module '@material-ui/core/styles' {
  export interface Theme extends IRTheme {}
  export interface ThemeOptions extends IRTheme {}
}
export type StatusColorsConfig = IRTheme['status'][keyof IRTheme['status']];
export const darkTheme = createTheme(themeOptions as ThemeOptions);
export const lightTheme = createTheme(lightThemeOptions as ThemeOptions);

export const theme = darkTheme;

export function createThemeWithOverrides(overrides: Partial<ThemeOptions>) {
  const calculateOverrides = overrides.palette.type === 'light' ? merge(lightThemeOptions, overrides) : overrides;
  return createTheme(merge(themeOptions, calculateOverrides) as IRTheme & ThemeOptions);
}
